import React from 'react';
import Text from '../Text';
import './ShadowedButton.css';

type ShadowedButtonProps = {
  onClick?: () => void;
};

const ShadowedButton: React.FC<ShadowedButtonProps> = ({
  onClick,
  children,
}) => {
  return (
    <button onClick={onClick} type='button' className='container'>
      {children}
    </button>
  );
};

export default ShadowedButton;
