import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className='header'>
      <div className='header-content'>
        <a className='logo'>JH</a>
      </div>
    </div>
  );
};

export default Header;
