import React from 'react';
import ShadowedButton from './Buttons/ShadowedButton';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-content'>
        <div>JH</div>

        <ShadowedButton>Get in touch</ShadowedButton>
      </div>
    </div>
  );
};

export default Footer;
