import React from 'react';
import './Text.css';

type TextProps = {};

const Text: React.FC<TextProps> = ({ children }) => {
  return <div className='gilroy-regular'>{children}</div>;
};

export default Text;
