import React from 'react';
import logo from './logo.svg';
import './App.css';

import Text from './components/Text';
import Header from './components/Header';
import ShadowedButton from './components/Buttons/ShadowedButton';
import Footer from './components/Footer';

function App() {
  return (
    <div className='App'>
      <Header />
      <Footer />

      <div className='body-container'>
        <div
          style={{
            position: 'relative',
            top: '-30px',
          }}
        >
          <Text>I'm Joseph Huang</Text>
          <Text>A french mobile engineer</Text>

          {/* <ShadowedButton>Get in touch</ShadowedButton> */}
        </div>
      </div>
    </div>
  );
}

export default App;
